import axios from "axios";

// email integration
export const sendEmailNotifecation = async (
  location = "",
  reqBody = {},
  resError = {},
  icPath = ""
) => {
  const objToString = JSON.stringify(reqBody);
  const errToString = JSON.stringify(resError);
  let dataBody = {
    subject: `DX Payment page is down: ${location}`,
    body: `DX Payment is down. Please check the details below! 
          <br/><br/>
          reqBody: ${objToString}, <br/><br/> resError: ${errToString}, 
          <br/><br/> icPath: ${icPath}, <br/><br/> uiPath: ${location}`,
  };

  //   console.log("dataBody", dataBody);

  const Options = {
    method: "post",
    url: "https://fdcpnqjpac.execute-api.us-west-2.amazonaws.com/dx-email-prompt",
    headers: {
      "Content-Type": "application/json",
    },
    data: dataBody,
  };

  try {
    await axios(Options);
    // console.log("response", response);
    return true;
  } catch (error) {
    console.error("Error in email request", error);
    return false;
  }
};
