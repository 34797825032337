import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Loader from "react-loader-spinner";
import { StaticImage } from "gatsby-plugin-image";
import Confetti from "react-confetti";

// config
import config from "../../config";

// utils
import { formatNumber } from "../../utils";

// services
import { sendToLSQ } from "../../services/LeadSquared";
import { sendToCT } from "../../services/Clevertap";

// components
import BookPackCouponModal from "./BookPackCouponModal";
import BookPackTermsModal from "./BookPackTermsModal";
import WhatsIncludedModal from "./WhatsIncludedModal";

// css
import * as styles from "./BookPack.module.css";

// Confetti
var windowWidth, windowHeight;

if (typeof window !== `undefined`) {
  windowWidth = parseInt(window.innerWidth);
  windowHeight = parseInt(window.innerHeight);

  if (windowWidth <= 768) {
    windowWidth = parseInt(window.innerWidth);
    windowHeight = "2000px";
  } else {
    windowWidth = parseInt(window.innerWidth);
    windowHeight = "1200px";
  }
}

const BookPackForm = props => {
  const [hasApplied, setHasApplied] = useState(false);
  const [successMsg, setsuccessMsg] = useState("");
  const [city, setCity] = useState("");
  const [loading, setLoading] = useState(false);
  const [validPinCode, setValidPinCode] = useState(false);
  const [pinCodeMessage, setPinCodeMessage] = useState("");
  const [prevPinCode, setPrevPinCode] = useState("");
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showTermsCanvasModal, setShowTermsCanvasModal] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showCouponCanvasModal, setShowCouponCanvasModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
    setValue,
  } = useForm({ mode: "all" });

  useEffect(() => {
    setValue("name", props.name || "");
    setValue("email", props.email || "");
    setValue("phone", props.phone || "");
    if (props.postalCode) {
      setValue("pinCode", props.postalCode, { shouldValidate: true });
    }
  }, [props.name, props.email, props.phone, props.postalCode, setValue]);

  const onSubmit = async data => {
    if (loading) {
      console.log("pincode is still loading...");
      return false;
    }

    data.pinCodeMessage = pinCodeMessage;
    data.city = city;
    props.handlePayNow(data);

    // leadSquared integration
    let leadSquaredParams = {
      FirstName: data.name,
      Phone: data.phone,
      EmailAddress: data.email,
      mx_Website_CTA: props.leadSquaredParam,
      mx_Latest_Source: "New Website",
    };

    try {
      // setLoading(true);
      await sendToLSQ(leadSquaredParams);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Leadsquared server error", error);
    }
  };

  const validatePinCode = async value => {
    if (prevPinCode === value) {
      return true;
    }

    if (value.length === 6) {
      const options = {
        method: "get",
        url: config.DX_API_BASE_URL + "/api/pincodes/" + value,
        headers: {
          Authorization: `Bearer ${config.JWT_TOKEN}`,
        },
      };

      try {
        setLoading(true);
        setValidPinCode(false);
        setPinCodeMessage("");
        const response = await axios(options);
        const foundPincode = response.data.results;
        setLoading(false);
        if (foundPincode.length === 0) {
          return "Your Pin code is not serviceable";
        }

        const labValue = foundPincode[0]["labServiceability"] || "";
        const tsaValue = foundPincode[0]["tsaServiceability"] || "";
        const city = foundPincode[0].city || "";

        setCity(city);

        if (labValue === "Yes" && tsaValue === "Yes") {
        } else if (
          labValue === "Need to check" ||
          tsaValue === "Need to check"
        ) {
          setPinCodeMessage("Check by Ops");
        } else {
          return "Your Pin code is not serviceable";
        }
        setPrevPinCode(value);
        setValidPinCode(true);
        return true;
      } catch (error) {
        setLoading(false);
        console.error("Pin code server error", error);
        return "Pin code server error";
      }
    } else {
      return "Please enter your valid pin code";
    }
  };

  // Terms & conditions Modal
  const handleTermsModal = () => {
    setShowTermsCanvasModal(true);
    setShowTermsModal(true);
  };

  // Coupon Modal
  const handleCouponModal = () => {
    setShowCouponModal(true);
    setShowCouponCanvasModal(true);
  };

  return (
    <>
      <div>
        {/* Show Confetti */}
        {showConfetti === true ? (
          <Confetti width={windowWidth} height={windowHeight} />
        ) : (
          ""
        )}
        <div className={`container twinContainer ${styles.bookPackForm_sec}`}>
          {/* Text */}
          <div className={`${styles.bookPack_text_sec}`}>
            <div className="row">
              <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                <div className={styles.bookPackForm_cnt_1_sec}>
                  <p>Join Twin’s 1-year program</p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                {props.windowSize && props.windowSize.width <= 768 ? (
                  <div
                    className={styles.bookPackForm_cnt_2_sec}
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasBottom"
                    aria-controls="offcanvasBottom"
                    onClick={() =>
                      sendToCT("Enroll Program Viewed", {
                        action: "Enroll_Program_Inclusion_Trial Clicked",
                      })
                    }
                    role="presentation"
                  >
                    <p>What’s included in the plan?</p>
                  </div>
                ) : (
                  <div
                    className={styles.bookPackForm_cnt_2_sec}
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    onClick={() =>
                      sendToCT("Enroll Program Viewed", {
                        action: "Enroll_Program_Inclusion_Trial Clicked",
                      })
                    }
                    role="presentation"
                  >
                    <p>What’s included in the plan?</p>
                  </div>
                )}
              </div>
            </div>
            <div style={{ position: "relative" }}>
              {/* To draw the line */}
              <div className={styles.bookpackForm_mob_line}></div>
              <div className={styles.bookpackForm_desk_line}></div>
            </div>
          </div>
          {/* Card  */}
          <div className={styles.top_card_details_sec}>
            <div className="row mt-6">
              <div className="col-md-3">
                <div className={styles.top_card_details_1}>
                  <StaticImage
                    src="../../images/bookPack/icon_limited.png"
                    className="img-fluid"
                    alt=""
                  />
                  <p>LIMITED PERIOD OFFER</p>
                </div>
              </div>
              <div className="col-md-9">
                <div className={styles.top_card_part_details_2}>
                  <p className={styles.top_card_part_details_2_p_1}>
                    Reserve your pack with{" "}
                    {formatNumber(Math.round(props.price))} now
                  </p>
                  <p className={styles.top_card_part_details_2_p_2}>
                    Pay remaining amount on receiving your twin diabetes
                    reversal kit
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* End - Card */}
          {/* Start - Form Text */}
          <div className="mt-4">
            <div className="row">
              <div className="col-12">
                <div className={styles.bookpackForm_formTxt}>
                  <p>Complete user details</p>
                </div>
              </div>
            </div>
          </div>
          {/* End - Form Text */}
          {/* Form */}
          <div>
            <form name="bookPack" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                {/* Start - Form_Input_Boxes */}
                {/* Name */}
                <div className={`col-md-4 ${styles.bookpackForm_form}`}>
                  <div className={`form-group ${styles.bookpackForm_formGrp}`}>
                    <label className="mb-2" htmlFor="name">
                      Full Name*
                    </label>
                    <input
                      className={
                        errors.name ? "form-control is-invalid" : "form-control"
                      }
                      placeholder="Full name"
                      id="name"
                      type="text"
                      {...register("name", {
                        required: "Please enter your name",
                        pattern: {
                          value: /[a-zA-Z][a-zA-Z ]*/, // eslint-disable-line no-useless-escape
                          message: "Please enter your valid name",
                        },
                        maxLength: { value: 50, message: "Name is too long" },
                      })}
                    />
                    {errors.name && (
                      <span className="invalid-feedback">
                        {errors.name.message}
                      </span>
                    )}
                  </div>
                </div>
                {/* Phone */}
                <div className={`col-md-4 ${styles.bookpackForm_form}`}>
                  <div className={`form-group ${styles.bookpackForm_formGrp}`}>
                    <label className="mb-2" htmlFor="phone">
                      Phone*
                    </label>
                    <input
                      className={`form-control letterSp1 ${
                        errors.phone ? "is-invalid" : ""
                      }`}
                      placeholder="Phone number"
                      id="phone"
                      type="text"
                      inputMode="numeric"
                      maxLength="10"
                      {...register("phone", {
                        required: "Please enter your mobile number",
                        pattern: {
                          value:
                            /^\+?([1-9]{1})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/, // eslint-disable-line no-useless-escape
                          message: "Please enter your valid phone number",
                        },
                        maxLength: {
                          value: 10,
                          message: "Invalid phone number",
                        },
                      })}
                    />
                    {errors.phone && (
                      <span className="invalid-feedback">
                        {errors.phone.message}
                      </span>
                    )}
                  </div>
                </div>
                {/* Email */}
                <div className={`col-md-4 ${styles.bookpackForm_form}`}>
                  <div className={`form-group ${styles.bookpackForm_formGrp}`}>
                    <label className="mb-2" htmlFor="email">
                      Email*
                    </label>
                    <input
                      className={
                        errors.email
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      placeholder="Email id"
                      id="email"
                      type="email"
                      {...register("email", {
                        required: "Please enter your email",
                        pattern: {
                          value:
                            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, // eslint-disable-line no-useless-escape
                          message: "Please enter your valid email",
                        },
                      })}
                    />
                    {errors.email && (
                      <span className="invalid-feedback">
                        {errors.email.message}
                      </span>
                    )}
                  </div>
                </div>
                {/* Pin Code */}
                <div className={`col-md-4 ${styles.bookpackForm_form}`}>
                  <div className={`form-group ${styles.bookpackForm_formGrp}`}>
                    <label className="mb-2" htmlFor="pinCode">
                      Pin Code*
                    </label>
                    <input
                      className={`form-control letterSp1 ${
                        errors.pinCode ? "is-invalid" : ""
                      }`}
                      placeholder="Pin code"
                      id="pinCode"
                      type="text"
                      maxLength="6"
                      inputMode="numeric"
                      {...register("pinCode", {
                        required: "Please enter your pin code",
                        pattern: {
                          value: /^[1-9][0-9]{5}$/, // eslint-disable-line no-useless-escape
                          message: "Please enter your valid pin code",
                        },
                        maxLength: { value: 6, message: "Please enter your valid pin code" },
                        validate: validatePinCode,
                      })}
                    />
                    {loading ? (
                      <span style={{ fontSize: "80%" }}>Loading...</span>
                    ) : errors.pinCode ? (
                      <span className="invalid-feedback">
                        {errors.pinCode.message}
                      </span>
                    ) : (
                      validPinCode && (
                        <span
                          className="text-success"
                          style={{ fontSize: "80%" }}
                        >
                          Your Pin code is serviceable
                        </span>
                      )
                    )}
                  </div>
                </div>
                {/* Coupon */}
                {props.showCouponSection ? (
                  <div className={`col-md-4 ${styles.bookpackForm_cpn_sec}`}>
                    {hasApplied === true && props.coupon !== "" ? (
                      <div className={styles.bookpackForm_cpn_appliedSec}>
                        <span className="text-success">
                          {successMsg}
                          <span
                            className={`icon-cross ${styles.bookpackForm_cpn_can_icn}`}
                            aria-hidden="true"
                            role="presentation"
                            onClick={() => {
                              setHasApplied(false);
                              props.removedCoupon(true);
                            }}
                          ></span>
                        </span>
                      </div>
                    ) : (
                      <div
                        className={styles.bookpackForm_cpn_cta}
                        role="presentation"
                        onClick={() => handleCouponModal()}
                      >
                        Apply Coupon Code
                        <span
                          className={`icon-coupon ${styles.bookpackForm_cpn_icn}`}
                        ></span>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}
                {/* End - Form_Input_Boxes */}

                {/* Start - Terms_&_Conditions  */}
                <div className="col-12 mt-4">
                  <div className={styles.bookpackForm_terms_condns_sec}>
                    <StaticImage
                      src="../../images/bookPack/check-mark.svg"
                      className="img-fluid"
                      alt=""
                      style={{
                        opacity: "0.54",
                        marginTop: "3px",
                        height: "15px",
                        width: "15px",
                      }}
                    />
                    <label htmlFor="terms_conditions">
                      I agree to all{" "}
                      <span
                        onClick={() => handleTermsModal()}
                        role="presentation"
                      >
                        Terms & Conditions
                      </span>
                    </label>
                  </div>
                </div>
                {/* End - Terms_&_Conditions  */}

                {/* Start - Buy_Now CTA Button */}
                <div className={`col-12 mt-4`}>
                  <div className="buttonClass">
                    <span className="errorclass text-center text-danger">
                      {!isValid &&
                        isSubmitted &&
                        "Please fill all the fields correctly"}
                    </span>
                    <button
                      type="submit"
                      className={`${styles.bookpackForm_cta} ${
                        props.loading && styles.bookpackForm_cta_event
                      }`}
                    >
                      <div className="bookBtn" id="book-now">
                        {hasApplied === true ? (
                          <span
                            className={`d-flex align-items-center ${
                              props.loading && styles.bookpackForm_cta_txt_evnt
                            }`}
                          >
                            <span className="bookBtn">Buy Now at</span>
                            <span
                              className={`bookBtn bookBtnAmt ${styles.bookpackForm_cta_amt}`}
                            >
                              <p>{formatNumber(props.price)}</p>
                            </span>
                            <span
                              className={`icon-arrow-forward ${styles.bookpackForm_cta_arrw}`}
                              aria-hidden="true"
                            ></span>
                          </span>
                        ) : (
                          <span
                            className={`d-flex align-items-center ${
                              props.loading && styles.bookpackForm_cta_txt_evnt
                            }`}
                          >
                            <span className="bookBtn">Buy Now at</span>
                            <span
                              className={`bookBtn bookBtnAmt ${styles.bookpackForm_cta_amt}`}
                            >
                              <p>{formatNumber(props.actualprice)}</p>
                            </span>
                            <span
                              className={`icon-arrow-forward ${styles.bookpackForm_cta_arrw}`}
                              aria-hidden="true"
                            ></span>
                          </span>
                        )}

                        <span className={styles.bookpackForm_cta_loader}>
                          <Loader
                            type="ThreeDots"
                            color="#FFF"
                            height={30}
                            width={30}
                            visible={props.loading}
                          />
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
                {/* End - Buy_Now CTA Button */}
              </div>
            </form>
          </div>
          {/* End- Form */}
        </div>

        {/* Coupon Modal */}
        <BookPackCouponModal
          show={showCouponModal}
          onHide={() => setShowCouponModal(false)}
          price={props.price}
          handleCouponUpdate={props.handleCouponUpdate}
          handleSuccessMsg={msg => {
            setHasApplied(true);
            setsuccessMsg(msg);
            setTimeout(() => setShowCouponModal(false), 2000);
            setShowConfetti(true);
            setTimeout(() => setShowConfetti(false), 7000);
          }}
          coupon={props.coupon}
          selectedPlan={props.selectedPlan}
          productId={props.productId}
          showCanvasModal={showCouponCanvasModal}
          onHideCanvasModal={() => setShowCouponCanvasModal(false)}
          windowSize={props.windowSize}
          couponType={props.couponType}
          isClassification={false}
        />

        {/* Terms & Conditions Modal */}
        <BookPackTermsModal
          show={showTermsModal}
          showTermsCanvasModal={showTermsCanvasModal}
          onHide={() => setShowTermsModal(false)}
          onHideCanvasModal={() => setShowTermsCanvasModal(false)}
          windowSize={props.windowSize}
        />

        {/* Whats Included Modal */}
        {props.windowSize && props.windowSize.width <= 768 ? (
          <WhatsIncludedModal
            id="offcanvasBottom"
            windowSize={props.windowSize}
          />
        ) : (
          <WhatsIncludedModal
            id="offcanvasRight"
            windowSize={props.windowSize}
          />
        )}
      </div>
    </>
  );
};

export default BookPackForm;
