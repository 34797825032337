import { useStaticQuery, graphql } from "gatsby";

export const useBookPackQuery = (component = "offer") => {
  const { allTwinPage } = useStaticQuery(
    graphql`
      query BOOK_PACK_QUERY {
        allTwinPage(filter: { page: { eq: "bookpack" } }) {
          edges {
            node {
              page
              component
              productId
              data {
                productId
                planName
                planCode
                offerPrice
                planPrice
                planDay
                planType
                TIER_I
                TIER_II
                bannerImage
                pageStatus
              }
            }
          }
        }
      }
    `
  );

  // console.log("all node", allTwinPage.edges);
  const findComponent = allTwinPage.edges.find(
    item => item.node.component === component
  );
  // console.log("findComponent", findComponent);

  let node = findComponent.node.data;
  // console.log(node);

  let result = {};
  node.map((item, index) => (result = { ...result, [item.planName]: [item] }));
  // console.log(result);
  return result;
};
