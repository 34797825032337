import { apiCalls } from "./apiCalls";

// config
import config from "../config";

// function -> to validate pin code
export const doValidatePincode = async value => {
  const url = config.DX_API_BASE_URL + "/api/pincodes/" + value;

  try {
    const result = await apiCalls("get", url);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error);
    } else {
      console.error("Pincode BookPack, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

// function -> to get the client details based on client id
export const getClientDetails = async clientId => {
  const url = config.DX_API_BASE_URL + "/api/clients/details/" + clientId;

  try {
    const result = await apiCalls("get", url);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error);
    } else {
      console.error("Client Details BookPack, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

// function -> payment details
export const handlePaymentOfBookPack = async paymentBody => {
  const url = config.DX_API_BASE_URL + "/api/clients/payment";

  try {
    const result = await apiCalls("post", url, paymentBody);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error);
    } else {
      console.error("Bookpack payment, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

// ====================================================================== //
// =================== REFERRAL BOOK PACK API'S ========================= //
// ====================================================================== //

// function -> To get the referrals info based on referral code
export const getReferralsDetails = async referralCode => {
  const url = config.DX_API_BASE_URL + "/api/referrals/" + referralCode;

  try {
    const result = await apiCalls("get", url);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error);
    } else {
      console.error("Referral Details BookPack, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

// function => To update the refree status
export const updateRefreeStatus = async (referralCode, clientId, reward) => {
  const url =
    config.DX_API_BASE_URL +
    "/api/referee/" +
    referralCode +
    `/?clientId=${clientId}&reward=${reward}`;

  try {
    const result = await apiCalls("post", url);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error);
    } else {
      console.error(
        "Refree update status BookPack, server err:",
        error.message
      );
      throw new Error(error.message);
    }
  }
};

// function => to update address of the new user
export const updateClientAddress = async clientInfoBody => {
  const url = config.DX_API_BASE_URL + "/api/clients/updateClientAddress";

  try {
    const result = await apiCalls("post", url, clientInfoBody);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error);
    } else {
      console.error(
        "Client details not get updated bookpack, server err:",
        error.message
      );
      throw new Error(error.message);
    }
  }
};

// function --> to get the coupon details
export const getCouponDetails = async (
  couponCode,
  pId,
  type,
  classification,
  treatment
) => {
  const url =
    config.DX_API_BASE_URL +
    "/api/marketing/coupons/" +
    couponCode +
    `/?pId=${pId}&type=${type}&classification=${classification}&treatment=${treatment}`;

  try {
    const result = await apiCalls("get", url);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error);
    } else {
      console.error(
        "Coupon details error, server err:",
        error.message
      );
      throw new Error(error.message);
    }
  }
};

// function --> to update user details on success payment in payment status screen
export const updateClientDetailsPaymentPage = async (reqBody) => {
  const url = config.DX_API_BASE_URL + "/api/clients/details";

  try {
    const result = await apiCalls("put", url, reqBody);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error);
    } else {
      console.error(
        "Client details not get updated bookpack, server err:",
        error.message
      );
      throw new Error(error.message);
    }
  }
}