import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Loader from "react-loader-spinner";

// utils
import { formatNumber } from "../../utils";

// actions
import { getCouponDetails } from "../../actions/bookPackActions";

// css
import * as styles from "./BookPack.module.css";

const CouponForm = props => {
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  let treatmentType = "";
  if (props.toggleButtonValue === "pilot_diabetes") {
    treatmentType = "diabetes";
  } else if (props.toggleButtonValue === "pilot_obesity") {
    treatmentType = "obesity";
  } else if (props.toggleButtonValue === "pilot_pre_diabetes") {
    treatmentType = "preDiabetes";
  } else if (props.toggleButtonValue === "pilot_metabolic_wellness") {
    treatmentType = "metabolicWellness";
  }

  const onSubmit = async data => {
    try {
      setLoading(true);
      setServerError("");

      // api call -> to get coupon details
      const response = await getCouponDetails(
        data.coupon.toUpperCase(),
        props.productId,
        props.couponType,
        props.selectedPlan,
        treatmentType
      );

      setLoading(false);
      if (response.results.length === 0) {
        setServerError("The coupon code is not valid");
      } else if (response.results[0]["status"].toLowerCase() === "expired") {
        setServerError("The coupon code is expired");
      } else {
        const discountedAmount = response.results[0]["discountedAmount"] || "";
        const newPlanPrice =
          parseInt(props.planPrice) - parseInt(discountedAmount);
        const discountPercent = Math.round(
          (discountedAmount / props.planPrice) * 100
        );

        // common coupon message shown to book pack pages
        if (props.couponMessageType === "default") {
          let msg =
            "Congratulations! you got an extra discount of " +
            formatNumber(discountedAmount);

          setSuccessMessage(msg);
          props.handleSuccessMsg(msg);
          props.handleCouponUpdate(newPlanPrice, data.coupon, discountedAmount);
        }

        // customized coupon message for enterprise book pack page
        if (props.couponMessageType === "enterpriseMsg") {
          if (props.toggleButtonValue === "pilot_diabetes") {
            if (props.selectedPlan === "annualPlan") {
              let msg = "Congratulations! You are eligble for Free Wearable";

              setSuccessMessage(msg);
              props.handleSuccessMsg(msg);
              props.handleCouponUpdate(
                newPlanPrice,
                data.coupon,
                discountedAmount
              );
            } else if (props.selectedPlan === "installmentPlan") {
              let msg =
                "Congratulations! You got " +
                discountPercent +
                "% Discount and eligible for Free Weigh Scale";
              setSuccessMessage(msg);
              props.handleSuccessMsg(msg);
              props.handleCouponUpdate(
                newPlanPrice,
                data.coupon,
                discountedAmount
              );
            } else {
              let msg =
                "Congratulations! You got " + discountPercent + "% discount";
              setSuccessMessage(msg);
              props.handleSuccessMsg(msg);
              props.handleCouponUpdate(
                newPlanPrice,
                data.coupon,
                discountedAmount
              );
            }
          } else if (props.toggleButtonValue === "pilot_obesity") {
            if (props.selectedPlan === "annualPlan") {
              let msg =
                "Congratulations! You are eligble for Free Wearable & Weigh Scale";
              setSuccessMessage(msg);
              props.handleSuccessMsg(msg);
              props.handleCouponUpdate(
                newPlanPrice,
                data.coupon,
                discountedAmount
              );
            } else if (props.selectedPlan === "installmentPlan") {
              let msg = "Congratulations! You are eligble for Free Wearable";
              setSuccessMessage(msg);
              props.handleSuccessMsg(msg);
              props.handleCouponUpdate(
                newPlanPrice,
                data.coupon,
                discountedAmount
              );
            } else if (props.selectedPlan === "quarterlyPlan") {
              let msg =
                "Congratulations! You got " +
                discountPercent +
                "% Discount and eligible for Free Weigh Scale";
              setSuccessMessage(msg);
              props.handleSuccessMsg(msg);
              props.handleCouponUpdate(
                newPlanPrice,
                data.coupon,
                discountedAmount
              );
            } else {
              let msg =
                "Congratulations! You got " + discountPercent + "% discount";
              setSuccessMessage(msg);
              props.handleSuccessMsg(msg);
              props.handleCouponUpdate(
                newPlanPrice,
                data.coupon,
                discountedAmount
              );
            }
          } else {
            if (props.selectedPlan === "annualPlan") {
              let msg = "Congratulations! You are eligble for Free Wearable";
              setSuccessMessage(msg);
              props.handleSuccessMsg(msg);
              props.handleCouponUpdate(
                newPlanPrice,
                data.coupon,
                discountedAmount
              );
            } else if (props.selectedPlan === "installmentPlan") {
              let msg =
                "Congratulations! You got " +
                discountPercent +
                "% Discount and eligible for Free Weigh Scale";
              setSuccessMessage(msg);
              props.handleSuccessMsg(msg);
              props.handleCouponUpdate(
                newPlanPrice,
                data.coupon,
                discountedAmount
              );
            } else {
              let msg =
                "Congratulations! You got " + discountPercent + "% discount";
              setSuccessMessage(msg);
              props.handleSuccessMsg(msg);
              props.handleCouponUpdate(
                newPlanPrice,
                data.coupon,
                discountedAmount
              );
            }
          }
        }
      }
    } catch (error) {
      setLoading(false);
      setServerError("Something went wrong. Please try after sometime");
      console.error("coupon code server error", error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-3">
      {successMessage === "" ? (
        <div className="row">
          <div className="col-md-12">
            <div className={`form-group  ${styles.bookpackForm_formGrp}`}>
              <input
                className={
                  errors.coupon ? "form-control is-invalid" : "form-control"
                }
                placeholder="Type coupon code"
                id="coupon"
                type="text"
                {...register("coupon", {
                  required: "Please enter coupon code",
                  pattern: {
                    value: /^[A-Za-z0-9]+$/,
                    message: "Please enter your valid coupon code",
                  },
                  minLength: { value: 4, message: "Coupon code is too small" },
                  maxLength: { value: 50, message: "Coupon code is too long" },
                })}
              />
              {errors.coupon && (
                <span className="invalid-feedback">
                  {errors.coupon.message}
                </span>
              )}
            </div>
          </div>

          <div className="col-md-12 text-center">
            <div className="mt-4">
              <button
                type="submit"
                className={`${styles.bookpackForm_cta} ${
                  loading && styles.bookpackForm_cta_event
                }`}
                style={{ width: "100%" }}
              >
                <div
                  className="bookBtn"
                  id="book-now"
                  style={{ margin: "auto" }}
                >
                  <span
                    className={`${loading && styles.bookpackForm_cta_txt_evnt}`}
                  >
                    <span className="bookBtn">Apply Now</span>
                  </span>
                  <span
                    className={`${styles.bookpackForm_cta_loader} ${styles.bookpackForm_cpnCta}`}
                  >
                    <Loader
                      type="TailSpin"
                      color="#FFF"
                      height={30}
                      width={30}
                      radius={1}
                      visible={loading}
                    />
                  </span>
                </div>
              </button>
            </div>
          </div>
          <div className="col-md-12 mt-2 text-center">
            <div className="text-danger">{serverError}</div>
          </div>
        </div>
      ) : (
        <div className="col-md-12 mb-4 text-center">
          <div className="text-success">{successMessage}</div>
        </div>
      )}
    </form>
  );
};

export default CouponForm;
