import React, { Component } from "react";
import queryString from "query-string";
import axios from "axios";

// app header
import AppHeader from "../components/AppHeader/AppHeader";

// config
import config from "../config";

// seo
import Seo from "../components/Seo";

// hook
import { useBookPackQuery } from "../hooks/useBookPackQuery";
import { useWindowSize } from "../hooks/useWindowSize";

// services
import { sendToCT } from "../services/Clevertap";
// email
import { sendEmailNotifecation } from "../services/SendEmail";

// componentts
import BookPackHeading from "../components/BookPack/BookPackHeading";
import BookPackTrialForm from "../components/BookPack/BookPackTrialForm";

class BookPackTrial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      serverError: "",
      type: "DIRECT_M2_ANNUAL",
      offerPrice: 0,
      planCode: "",
      planPrice: 0,
      coupon: "",
      couponApplied: false,
      couponStatus: "",
      actualprice: "",
      clintInfo: {},
      m2Plans: this.props.bookPack,
      annualPlanPrice: 0,
      selectedPlan: "annualPlan",
      productId: "",
    };
  }

  getInitialData = async clientId => {
    const options = {
      method: "get",
      url: config.DX_API_BASE_URL + "/api/clients/details/" + clientId,
    };

    try {
      const response = await axios(options);
      this.setState({ clintInfo: response.data.results[0], fetching: true });
    } catch (error) {
      console.error("client api server error", error);
      this.setState({ serverError: "try again", fetching: true });
    }
  };

  componentDidMount() {
    // window.scrollTo(0, 0);

    const quarterlyPlan = this.props.bookPack.quarterlyPlan[0];
    const annualPlan = this.props.bookPack.annualPlan[0];

    let plan = null;
    if (this.state.selectedPlan === "quarterlyPlan") {
      plan = quarterlyPlan;
    } else if (this.state.selectedPlan === "annualPlan") {
      plan = annualPlan;
    }

    plan &&
      this.setState({
        offerPrice: Number(plan.offerPrice),
        planCode: plan.planCode,
        planPrice: Number(plan.planPrice),
        planDay: plan.planDay,
        actualprice: Number(plan.planPrice),
        type: plan.planType,
        productId: plan.productId,
        annualPlanPrice: Number(annualPlan.planPrice),
      });

    const { clientId } = queryString.parse(this.props.location.search);
    // console.log("clientId", clientId);
    if (clientId) {
      this.getInitialData(clientId);
    }
  }

  // PAYMENT
  handlePayNow = async values => {
    // payment v3
    let paymentBody = {
      firstName: values.name.split(" ")[0] || values.name,
      lastName: values.name.split(" ")[1] || "",
      mobileNumber: values.phone,
      email: values.email,
      planCode: this.state.planCode,
      preferredM2Plan: this.state.type,
      m1PlanCode: this.state.planCode,
      m2PlanCode: this.state.planCode,
      sourceType: "DIGITAL_ORGANIC_TW",
      couponCode: this.state.coupon.toUpperCase(),
      address: {
        postalCode: values.pinCode ? values.pinCode : "",
        city: values.city,
        line1: "",
      },
    };

    const paymentOptions = {
      method: "post",
      url: config.DX_API_BASE_URL + "/api/clients/payment",
      headers: {
        Authorization: `Bearer ${config.JWT_TOKEN}`,
      },
      data: paymentBody,
    };

    try {
      this.setState({ loading: true });
      const response = await axios(paymentOptions);
      const paymentUrl = response.data.results[0].url;

      this.setState({ loading: false });
      window.location.href = paymentUrl;

      // clevertap event
      sendToCT("Enrollment Initiated Trial", {
        action: "BookPack_Trial_Buy_Now Clicked",
        name: values.name,
        phone: values.phone,
        email: values.email,
        pincode: values.pinCode,
        coupon: this.state.coupon,
        paymentPlan: this.state.planCode,
      });
    } catch (error) {
      console.error("payment api server error", error);
      this.setState({
        loading: false,
        serverError:
          "There seems to be an issue at our end. Please call +91 9005008946 to complete the payment",
      });
      // send an email
      sendEmailNotifecation(
        window.location.href,
        paymentBody,
        error.response.data || {},
        "/marketing/v3/prospect"
      );
    }
  };

  // COUPON APPLIED
  handleCouponUpdate = (planPrice, coupon) => {
    this.setState({
      showConfetti: true,
      planPrice: Number(planPrice),
      coupon: coupon,
      couponApplied: true,
      annualPlanPrice: Number(planPrice),
    });

    // update plan price of selected plan after coupon applied
    // if (selectedPlan === "quarterlyPlan")
    //   this.setState({ quarterlyPlanPrice: Number(planPrice) });
    // if (selectedPlan === "annualPlan")
    //   this.setState({ annualPlanPrice: Number(planPrice) });

    setTimeout(
      function () {
        this.setState({ showConfetti: false });
      }.bind(this),
      5000
    );
  };

  // COUPON REMOVED
  removedCoupon = () => {
    const { selectedPlan } = this.state;
    const BOOK_PACK = this.state.m2Plans;
    const plan = BOOK_PACK[selectedPlan][0];

    this.setState({
      offerPrice: Number(plan.offerPrice),
      planCode: plan.planCode,
      planPrice: Number(plan.planPrice),
      planDay: plan.planDay,
      actualprice: Number(plan.planPrice),
      type: plan.planType,
      couponApplied: false,
      coupon: "",
      m2Plans: BOOK_PACK,
    });

    // update plan price of selected plan after coupon removed
    if (selectedPlan === "annualPlan")
      this.setState({ annualPlanPrice: Number(plan.planPrice) });
  };

  render() {
    const {
      actualprice,
      annualPlanPrice,
      clintInfo,
      loading,
      m2Plans,
      productId,
      planPrice,
      selectedPlan,
      serverError,
    } = this.state;

    const { appHeader } = queryString.parse(this.props.location.search);

    return (
      <div className="bgLightgray">
        <Seo
          title="Diabetes Reversal Program"
          description="Start your diabetes reversal journey. 1000's have reversed their diabetes using Twin's proven AI based precision treatment."
        />

        {appHeader === "mobile" && <AppHeader text="BOOK PACK" isBack={true} />}

        {/* Heading */}
        <BookPackHeading />

        {/* Form */}
        <BookPackTrialForm
          m2Plans={m2Plans}
          price={planPrice}
          actualprice={actualprice}
          handlePayNow={this.handlePayNow}
          loading={loading}
          handleCouponUpdate={this.handleCouponUpdate}
          coupon={this.state.coupon}
          removedCoupon={this.removedCoupon}
          selectedPlan={selectedPlan}
          name={clintInfo.name}
          email={clintInfo.email}
          phone={clintInfo.phone}
          postalCode={clintInfo.postalCode}
          locationSearch={this.props.location.search}
          productId={productId}
          annualPlanPrice={annualPlanPrice}
          showCouponSection={true}
          leadSquaredParam="book_pack_trial"
          windowSize={this.props.windowSize}
          couponType="bookpackCoupon"
        />
        <div className="mt-2 text-center text-danger">{serverError} &nbsp;</div>
      </div>
    );
  }
}

const WithContext = props => {
  const bookPackPayload = useBookPackQuery("trial");
  const windowSize = useWindowSize();
  return (
    <BookPackTrial
      {...props}
      bookPack={bookPackPayload}
      windowSize={windowSize}
    />
  );
};

export default WithContext;
