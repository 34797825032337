import axios from "axios";

// config
import config from "../config";

// api calls
export const apiCalls = (method, url, data = {}) => {
  if (method === "get") {
    // only for get
    return axios({ method: method, url: url, params: data });
  } else {
    // post, put, delete
    return axios({ method: method, url: url, data: data });
  }
};

/*
 * Added axios global variable
 */

// Add a request interceptor
axios.interceptors.request.use(
  data => {
    const token = config.JWT_TOKEN;
    if (token) {
      data.headers["Authorization"] = `Bearer ${token}`;
    }
    return data;
  },
  error => {
    return Promise.reject(error);
  }
);
